<template>
    <vs-popup class="terminalAdd_popup" title="Ajouter terminal" :active.sync="popupActive">
        <div class="vx-col w-full">
            <vs-input class="w-full" label="Identifiant terminal" v-model="terminal"/>
        </div>
        <br/>
        <vs-row>
            <vs-col vs-type="flex" vs-justify="space-between" vs-align="center" vs-xs="12">
                <div></div>
                <vs-button color="success" type="filled" v-on:click="add()" icon-pack="feather" icon="icon-plus">Ajouter</vs-button>
            </vs-col>
        </vs-row>
    </vs-popup>
</template>

<style lang="scss">
.terminalAdd_popup
{

}
</style>

<script>

import Terminal  from '@/database/models/terminal';

export default {
    components:{
	},
    props: {
    },
    data(){
        return{
            popupActive:false,
            callback:null,

            terminal:'',
        }
    },
    watch:{
        $route (){
        },
    },
    beforeMount:function(){
        
    },
    methods:{
        openPopup( data, callback )
        {
            this.callback    = null
            this.popupActive = true

            if(callback)
                this.callback = callback

        },
        add(){
            if(this.terminal.length<2)
                return alert('Nom terminal trop court !')

            Terminal.add({
                mac    : this.terminal,
                active : 1,
                admin  : 1,
            })
            .then(()=>{
                alert('Terminal ajouté.')
                if(this.callback)
                    this.callback()
            })
            this.popupActive = false
        },
    }
}

</script>