<template>
    <div class="option_terminal">
        <vs-row>
            <vs-col vs-type="flex" vs-justify="space-between" vs-align="center" vs-xs="12">
                <h3>Terminal</h3>
                <vs-button color="success" type="filled" v-on:click="openAddTerminal" icon-pack="feather" icon="icon-plus" >Ajouter</vs-button>
            </vs-col>
        </vs-row>

        <vs-divider/>
        <div>
            <div v-for="term in list_terminal" :key="term.uid">
                <vx-card>
                    <vs-row class="container">
                        <vs-col vs-type="flex" vs-justify="space-between" vs-align="center" vs-xs="12">
                            <b>{{ term.mac }}</b>
                            <a href="#" v-on:click="viewTicketZ(term.uid)">Ticket Z</a>
                            <!-- <a href="#" v-on:click="viewCertiticat(term.mac)">Certificat PDF</a> -->
                            <a href="#" v-on:click="cloture(term.uid,term.mac )">Attestation caisse</a>
                        </vs-col>
                    </vs-row>
                    <vs-divider/>
                    Aucune cloture
                </vx-card>
                <br/>
            </div>
        </div>
        <AddTerminal ref="addTerminal" />
        <PopupCloture ref="popupCloture" />
        <Close ref="popClose"/>
    </div>
</template>



<style lang="scss">
.option_terminal{

}
</style>

<script>

import autoRefresh from '@/assets/utils/autoRefresh'

import Terminal from '@/database/models/terminal'

import AddTerminal from '@/components/terminal/add.vue'
import Close from '@/components/terminal/exportCSV.vue'
import PopupCloture from '@/components/terminal/cloture.vue'

export default {
    components:{
        AddTerminal,
        PopupCloture,
        Close
	},
    data(){
        return{
            list_terminal:[],
        }
	},
	mounted:function()
	{
        //autoRefresh
		autoRefresh.SetCallback(()=>{
            //refresh
            Terminal.getTabAllMemory((list_term)=>{
                this.list_terminal = list_term
            })
        })
        autoRefresh.refresh()
    },
    watch:{
        $route (){
            autoRefresh.refresh()
        }
    },
    methods:{
		openAddTerminal(){
            this.$refs.addTerminal.openPopup()
        },
        viewCertiticat( mac ){
            this.$router.push('/admin/file/'+ encodeURI(btoa('studio/terminal/certificat/'+mac)))
        },
        viewTicketZ( mac )
        {
            this.$refs.popupCloture.openPopup( mac )
        },
        cloture ( mac, nom ){
            
            this.$refs.popClose.getCloture(mac,nom);
        }
    }
};

</script>